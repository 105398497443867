import { Link } from "react-router-dom";
import MenuFooter from "./MenuFooter";
import MenuSide from "./MenuSide";
//import GlobalContext.js
import { useGlobalContext } from "./GlobalContext";
import config from "./config";

//create function component as login
export default function ProfileMenu() {

    const { isLoading, setIsLoading, isAppError, setAppError, appErrorMessage, setAppErrorMessage, appErrorTitle, setAppErrorTitle, appErrorMode, setAppErrorMode, appUser, setAppUser, isLogin, setIsLogin, isLogoutRequest, setIsLogoutRequest } = useGlobalContext();

    // Add logout handler function
    const handleLogout = () => {
        localStorage.removeItem('oojwt');
        setIsLogin(false);
        setAppUser(null);
        setIsLogoutRequest(true);
    }

    // Update the logout list item to use onClick handler
    <li>
        <div className="profile-box">
            <div className="profile-icon">
                <img className="img-fluid icon" src="/assets/system/icons/logout.png" alt="logout" />
            </div>
            <Link onClick={handleLogout} className="profile-box-name">
                <h5>Log Out</h5>
                <i className="ri-arrow-right-s-line arrow"></i>
            </Link>
        </div>
    </li>
    return (
        <>
            <MenuSide />

            <section className="d-none">
                <div className="custom-container">
                    <div className="profile-cover">
                        <img className="img-fluid profile-pic" src={config.logo} alt="profile" />
                    </div>
                    <div className="profile-name">
                        <h5>{appUser.name}</h5>
                        <h6>{appUser.email}</h6>
                        <h6>{appUser.mobile}</h6>
                    </div>
                </div>
            </section>

            <section>
                <div className="custom-container">
                    <ul className="profile-list">
                        <li>
                            <div className="profile-box">
                                <div className="profile-icon">
                                    <img className="img-fluid icon" src="/assets/system/icons/profile.png" alt="profile" />
                                </div>
                                <Link to="/profileupdate" className="profile-box-name">
                                    <h5>Profile</h5>
                                    <i className="ri-arrow-right-s-line arrow"></i>
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div className="profile-box">
                                <div className="profile-icon">
                                    <img className="img-fluid icon" src="/assets/system/icons/contact.png" alt="bell" />
                                </div>
                                <Link to="/profilecontactupdate" className="profile-box-name">
                                    <h5>Contact Details</h5>
                                    <i className="ri-arrow-right-s-line arrow"></i>
                                </Link>
                            </div>
                        </li>

                        <li className="d-none">
                            <div className="profile-box">
                                <div className="profile-icon">
                                    <img className="img-fluid icon" src="/assets/system/icons/bank.png" alt="location" />
                                </div>
                                <Link to="/profilebankupdate" className="profile-box-name">
                                    <h5>Bank Details</h5>
                                    <i className="ri-arrow-right-s-line arrow"></i>
                                </Link>
                            </div>
                        </li>

                        <li>
                            <div className="profile-box">
                                <div className="profile-icon">
                                    <img className="img-fluid icon" src="/assets/system/icons/kyc.png" alt="wishlist" />
                                </div>
                                <Link to="/profilekycupdate" className="profile-box-name">
                                    <h5>KYC Verification</h5>
                                    <i className="ri-arrow-right-s-line arrow"></i>
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div className="profile-box">
                                <div className="profile-icon">
                                    <img className="img-fluid icon" src="/assets/system/icons/menu.png" alt="order" />
                                </div>
                                <Link to="/kitchenmenulist" className="profile-box-name">
                                    <h5>Kitchen Menu</h5>
                                    <i className="ri-arrow-right-s-line arrow"></i>
                                </Link>
                            </div>
                        </li>
                        <li className="d-none">
                            <div className="profile-box">
                                <div className="profile-icon">
                                    <img className="img-fluid icon" src="/assets/system/icons/coupon.png" alt="ticket" />
                                </div>
                                <Link to="/couponlist" className="profile-box-name">
                                    <h5>Coupon</h5>
                                    <i className="ri-arrow-right-s-line arrow"></i>
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div onClick={handleLogout} className="profile-box">
                                <div className="profile-icon">
                                    <img className="img-fluid icon" src="/assets/system/icons/logout.png" alt="logout" />
                                </div>
                                <Link  className="profile-box-name">
                                    <h5>Log Out</h5>
                                    <i className="ri-arrow-right-s-line arrow"></i>
                                </Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>

            <MenuFooter />
        </>
    );
}