//write a function to connect to rest api with axio in post method called UserLogin
import axios from 'axios';
import config from '../config';

const UserLogin = async (username, password) => {
    try {
        
        const FormData = require('form-data');
        let data = new FormData();
        data.append('mobile', username);
        data.append('password', password);
        
        const loginData = {
            mobile: username,
            password: password
        };

        const response = await axios.post(config.apiUrl + 'user/login', loginData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Required for sending form data
            },
        });

        if( response.status === 200 ){
            localStorage.setItem("oojwt", response.data.token);
            
           
            return {
                status: 200,
                message: response.data.message
            };
        }
    } catch (error) {
        console.error(error);
        if( error.response.status === 401 ){
            return {
                status: 401,
                message: error.response.data.message
            };
        }
    }
}

//create a function to hit rest api as post with axios with jwt from localstorage in the header
const LoggedUserDetail = async () => {
    try {
        const token = localStorage.getItem("oojwt");
        const response = await axios.post(config.apiUrl + 'user/get-by-jwt', {}, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        if( response.status === 200 ){
            console.log("user id : ", response.data.user.id);
            const response2 = await axios.get(config.apiUrl + 'kitchen/get-by-user-id?user_id='+ response.data.user.id, {
                headers: {
                    'Authorization': 'Bearer ' + token, // Required for sending form data
                },
            });

            console.log(response2);
            return {
                status: 200,
                message: response.data.message,
                user: response.data.user,
                kitchen: response2.data.kitchen
            };
        }
    } catch (error) {
        console.log(error);
        if( error.response.status === 401 ){
            return {
                status: 401,
                message: error.response.data.message
            };
        }
    }
}

export { UserLogin, LoggedUserDetail };