import { useState } from "react";
import MenuFooter from "./MenuFooter";
import MenuSide from "./MenuSide";
import { Link } from "react-router-dom";

//import Kitcenservice
//import KitchenService from "./service/KitchenService";
import { useEffect } from 'react';
import { useGlobalContext } from "./GlobalContext";
import { GetByKitchenId } from "./service/InvoiceService";

//create function component as login
export default function Dashboard() {

    const { isLoading, setIsLoading, isAppError, setAppError, appErrorMessage, setAppErrorMessage, appErrorTitle, setAppErrorTitle, appErrorMode, setAppErrorMode, appUser, setAppUser, isLogin, setIsLogin, isLogoutRequest, setIsLogoutRequest, appKitchen } = useGlobalContext();
    const [openStatus, setOpenStatus] = useState(false);

    const [invoices, setInvoices] = useState(null);
    const [overallKitchenShare, setOverallKitchenShare] = useState(0);
    const [overallPartnerShare, setOverallPartnerShare] = useState(0);

    const toggleOpenStatus = () => {
        var status = 'closed';
        if (openStatus === false) {
            status = 'active';
        }
        setIsLoading(true);
        //KitchenFunctions.changeStatus(status);
        setOpenStatus(!openStatus);
        setIsLoading(false);
    };

    //function to get all the cities
    const makeDashboard = async () => {
        /*
        if( appUser.status === 'active' ){
            setOpenStatus(true);
        }
        else if( appUser.status === 'closed' ){
            setOpenStatus(false);
        } 
            */

        const responseInvoices = await GetByKitchenId(appKitchen.id);
        if (responseInvoices.status === 200) {
            console.log("fetch data of invoices :", responseInvoices.invoices);
            setOverallKitchenShare(responseInvoices.overallKitchenShare);
            setOverallPartnerShare(responseInvoices.overallPartnerShare);
            setInvoices(responseInvoices.invoices);
        }
    }

    //useEffect to call getAllCitys on component load
    useEffect(() => {
        makeDashboard();
    }, []);

    return (
        <>
            <MenuSide />

            <section className="section-t-space">
                <div className="custom-container">
                    <div className="status-box">
                        <div>
                            <h5>Available Status</h5>
                            {openStatus ? (
                                <h6>Restaurant Open</h6>
                            ) : (
                                <h6 className="text-danger">Restaurant Closed</h6>
                            )}
                        </div>
                        <div className="switch-btn">
                            <input id="dir-switch" type="checkbox" checked={openStatus} onChange={toggleOpenStatus} />
                        </div>
                    </div>

                    <div className="total-sales-background mt-3">
                        <div className="earning-card-details">
                            <div className="w-100">
                                <div className="d-flex align-items-center">
                                    <img className="img-fluid icon " src="../assets/images/svg/empty-wallet.svg" alt="wallet-money" />
                                    <div className="ps-2">
                                        <h5 className="dark-text">Today Sales</h5>
                                    </div>
                                    <h2 className="ms-auto"><i className="ri-money-rupee-circle-line"></i> {invoices && invoices.length} Nos</h2>
                                </div>
                            </div>
                        </div>

                        <ul className="sale-timing-list">
                            <li className="w-100">
                                <div className="sale-timing-box">
                                    <h6 className="fw-normal dark-text">Kitchen Earning</h6>
                                    <h3 className="fw-semibold theme-color"><i className="ri-money-rupee-circle-line"></i> Rs. {overallKitchenShare}</h3>
                                </div>
                            </li>
                            <li className="w-100">
                                <div className="sale-timing-box">
                                    <h6 className="fw-normal dark-text">Oovanoo Share</h6>
                                    <h3 className="fw-semibold theme-color"><i className="ri-money-rupee-circle-line"></i> Rs. {overallPartnerShare}</h3>
                                </div>
                            </li>
                        </ul>
                        <div>
                            <Link to="/ordersearch/new" className="dashboard-button-big"> View Details </Link>
                        </div>
                    </div>

                </div>
            </section>

            <section className="section-b-space">
                <div className="custom-container">
                    <div className="row">
                        <div className="col-12">
                            <div className="dashboard-banner-image-section">
                                <img src="https://marketplace.canva.com/EAGTJ2KjXVo/3/0/1600w/canva-orange-and-red-modern-restaurant-promotion-outdoor-banner-2Q8rbxDgzuM.jpg" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-b-space">
                <div className="custom-container">
                    <div className="row justify-content-center">
                        <div className="col-4">
                            <Link to="/brandofficier" className="icon-box">
                                <i className="ri-headphone-fill"></i>
                                <br></br>
                                Help
                            </Link>
                        </div>
                        <div className="col-4">
                            <button className="icon-box">
                                <i className="ri-restaurant-line"></i>
                                <br></br>
                                Menu
                            </button>
                        </div>
                        <div className="col-4">
                            <button className="icon-box">
                                <i className="ri-wallet-line"></i>
                                <br></br>
                                Wallet
                            </button>
                        </div>
                        <div className="col-4">
                            <button className="icon-box">
                                <i className="ri-wallet-line"></i>
                                <br></br>
                                Orders
                            </button>
                        </div>
                    </div>
                    <div className="row justify-content-center d-none">
                        <div className="col-4">
                            <button className="icon-box">
                                <i className="ri-star-fill"></i>
                                <br></br>
                                Ratings
                            </button>
                        </div>
                        <div className="col-4">
                            <button className="icon-box">
                                <i className="ri-line-chart-line"></i>
                                <br></br>
                                Growth
                            </button>
                        </div>
                        <div className="col-4">
                            <button className="icon-box">
                                <i className="ri-restaurant-line"></i>
                                <br></br>
                                Menu
                            </button>
                        </div>
                        <div className="col-4">
                            <button className="icon-box">
                                <i className="ri-star-fill"></i>
                                <br></br>
                                Ads
                            </button>
                        </div>
                        <div className="col-4">
                            <button className="icon-box">
                                <i className="ri-home-smile-2-line"></i>
                                <br></br>
                                Coupons
                            </button>
                        </div>
                        <div className="col-4">
                            <button className="icon-box">
                                <i className="ri-wallet-line"></i>
                                <br></br>
                                Payouts
                            </button>
                        </div>
                        <div className="col-4">
                            <Link to="/brandofficier" className="icon-box">
                                <i className="ri-headphone-fill"></i>
                                <br></br>
                                Help
                            </Link>
                        </div>
                        <div className="col-4">
                            <button className="icon-box">
                                <i className="ri-folder-chart-line"></i>
                                <br></br>
                                Reports
                            </button>
                        </div>
                    </div>
                </div>
            </section>


            <MenuFooter />

        </>
    );
}