//function component in the name App
//implent react router dom
import React, { useState } from 'react';
import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Login';
import LoginOtp from './LoginOtp';
import Home from './Home';
import ProfileUpdate from './ProfileUpdate';
import Dashboard from './Dashboard';
import ProfileMenu from "./ProfileMenu";
import ProfileContactUpdate from "./ProfileContactUpdate";
import ProfileBankUpdate from "./ProfileBankUpdate";
import ProfileKycUpdate from "./ProfileKycUpdate";
import OrderSearch from "./OrderSearch";
import OrderDetail from "./OrderDetail";
import ProfilePasswordUpdate from "./ProfilePasswordUpdate";
import CouponAdd from "./CouponAdd";
import CouponList from "./CouponList";
import KitchenMenuList from "./KitchenMenuList";
import KitchenMenuAdd from "./KitchenMenuAdd";
import Wallet from "./Wallet";
import BrandOfficier from "./BrandOfficier";
import { useGlobalContext } from './GlobalContext';
import { LoggedUserDetail } from './service/UserService';
import MenuSide from './MenuSide';

export default function App() {

  const { isLoading, setIsLoading, isAppError, setAppError, appErrorMessage, setAppErrorMessage, appErrorTitle, setAppErrorTitle, appErrorMode, setAppErrorMode, appUser, setAppUser, isLogin, setIsLogin, isLogoutRequest, setIsLogoutRequest, appKitchen, setKitchenUser } = useGlobalContext();

  //check the getCurrentUser
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setIsLoading(true);
        const response = await LoggedUserDetail();
        if (response.status === 200) {
          setAppUser(response.user);
          setKitchenUser(response.kitchen);
          setIsLogin(true);
        }
      } catch (error) {
        setAppError(true);
        setAppErrorTitle("Error");
        setAppErrorMessage("Failed to load user details");
        setAppErrorMode("error");
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  const handleRetry = () => {
    setAppError(false); // Close the modal when retry is triggered
  };

  return (
    <>
      {/* loading screen */}
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
          <div className="text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">

              </span>
            </div>
            <p className="mt-3">Please wait...</p>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Modal Error Message */}
      {isAppError && (
        <div
          className="app-error-message modal d-block" // `d-block` ensures it always displays
          tabIndex="-1"
          role="dialog"
          aria-labelledby="errorModalLabel"
          aria-hidden="true"
          style={{ backgroundColor: 'rgba(0,0,0,0.5)' }} // Optional backdrop styling
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="errorModalLabel">
                  Error
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={handleRetry}
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  {appErrorMessage}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* add the routes */}
      <Router>
        <Routes>
          {isLogin ? (
            <>
            
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/profileupdate" element={<ProfileUpdate />} />
              <Route path="/profilecontactupdate" element={<ProfileContactUpdate />} />
              <Route path="/profilebankupdate" element={<ProfileBankUpdate />} />
              <Route path="/profilekycupdate" element={<ProfileKycUpdate />} />
              <Route path="/profilepasswordupdate" element={<ProfilePasswordUpdate />} />
              <Route path="/profilemenu" element={<ProfileMenu />} />

              <Route path="/ordersearch" element={<OrderSearch />} />
              <Route path="/ordersearch/:status" element={<OrderSearch />} />
              <Route path="/orderdetail/:id" element={<OrderDetail />} />

              <Route path="/kitchenmenulist" element={<KitchenMenuList />} />
              <Route path="/kitchemenuadd/:menuId" element={<KitchenMenuAdd />} />

              <Route path="/couponadd" element={<CouponAdd />} />
              <Route path="/couponlist" element={<CouponList />} />

              <Route path="/wallet" element={<Wallet />} />
              <Route path="/brandofficier" element={<BrandOfficier />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/loginotp/:mobile" element={<LoginOtp />} />
              <Route path="*" element={<Home />} />
            </>
          )}

          <Route path="*" element={<Home />} /> {/* 404 */}
        </Routes>
      </Router>
    </>
  );
}