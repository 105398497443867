// src/config.js
const applicationMode = "live";

var appName = "Oovanoo Kitchen App";
var apiUrl = "https://oovanoo.com/api-2/";
var pageSize = 10;
var logo = "https://oovanoo.com/api/logo.png";

if (applicationMode === 'development') {
  appName = "Oovanoo Office Administration";
  apiUrl = "http://localhost/oovano-back-end-api/";
}

const config = {
  appName: appName,
  apiUrl: apiUrl,
  pageSize: pageSize,
  logo: logo,
};


export default config;