import { Link } from "react-router-dom";
import MenuSide from "./MenuSide";
import { useState, useEffect } from "react";
import { useGlobalContext } from "./GlobalContext";
import KitchenFunctions from "./service/KitchenService";
import { mobileValidation, emailValidation } from './service/Validation';

//create function component as login
export default function ProfileContactUpdate() {

    const { isLoading, setIsLoading, isAppError, setAppError, appErrorMessage, setAppErrorMessage, appErrorTitle, setAppErrorTitle, appErrorMode, setAppErrorMode, appUser, setAppUser, isLogin, setIsLogin, isLogoutRequest, setIsLogoutRequest, appKitchen } = useGlobalContext();

    const [mobileAlternate, setMobileAlternate] = useState("");
    const [emailPrimary, setEmailPrimary] = useState("");
    const [emailAlternate, setEmailAlternate] = useState("");


    const updateContact = async () => {
        
        if (mobileAlternate === "") {
            setAppError(true);
            setAppErrorMessage("Alternate mobile number is required.");
            return;
        }
        // Validate name using `nameValidation` service
        if (!mobileValidation(mobileAlternate)) {
            setAppError(true);
            setAppErrorMessage("Please enter a valid 10-digit mobile number.");
            return;
        }
        if (emailPrimary === "") {
            setAppError(true);
            setAppErrorMessage("Primary email is required.");
            return;
        }
        if (!emailValidation(emailPrimary)) {
            setAppError(true);
            setAppErrorMessage("Enter a valid email address.");
            return;
        }
        if (emailAlternate === "") {
            setAppError(true);
            setAppErrorMessage("Alternate email is required.");
            return;
        }
        if (!emailValidation(emailAlternate)) {
            setAppError(true);
            setAppErrorMessage("Enter a valid email address.");
            return;
        }
        setIsLoading(true);

        alert("Contacts updated");
        window.location.href = "/profilemenu";
    }

    return (
        <>
            <MenuSide />

            <section className="section-b-space">
                <div className="custom-container">
                    <form className="auth-form" target="_blank">
                        <h4>Contact Details</h4>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Mobile Number</label>
                                    <input type="number" className="form-control shadow-none" placeholder="10 digit mobile number" readOnly value={appKitchen.user.mobile} />
                                </div>
                            </div>
                            
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Email Address</label>
                                    <input type="email" className="form-control shadow-none" placeholder="name@domain.com" readOnly value={appKitchen.user.email} onChange={(e) => setEmailPrimary(e.target.value)}  />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Owner Name</label>
                                    <input type="email" className="form-control shadow-none" placeholder="name@domain.com" value={appKitchen.user.name} onChange={(e) => setEmailAlternate(e.target.value)}  />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="fixed-btn">
                    <div className="custom-container">
                        <div className="d-flex align-items-center flex-nowrap gap-2">
                            <Link to="/profilemenu" className="btn gray-btn mt-0 w-100">Cancel</Link>
                            <button type="button" className="d-none btn theme-btn mt-0 w-100" onClick={(e)=>updateContact()}>Save</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}