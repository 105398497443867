import { Link } from "react-router-dom";
import MenuSide from "./MenuSide";
import { useGlobalContext } from "./GlobalContext";
import { useState, useEffect } from "react";
import { fssaiValidation, bankAccountNameValidation, bankAccountNumberValidation } from './service/Validation';

// Create function component as ProfileBankUpdate
export default function ProfileBankUpdate() {
    const { setIsLoading, setAppError, setAppErrorMessage } = useGlobalContext();

    // State variables for form inputs
    const [accountName, setAccountName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [ifscCode, setIfscCode] = useState("");

    // Fetch KYC data (if needed)
    const getKycData = async () => {
        try {
            setIsLoading(true);
            // Uncomment and implement fetching logic as needed
            // const response = await KitchenKycFunctions.getKyc();
            // Set initial state with response data if applicable
        } catch (error) {
            setAppError(true);
            setAppErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getKycData();
    }, []);

    // Validation function
    const validateForm = () => {
        if (!accountName) {
            setAppError(true);
            setAppErrorMessage("Please enter your bank account name.");
            return false;
        }
        if (!bankAccountNameValidation(accountName)) {
            setAppError(true);
            setAppErrorMessage("Invalid bank account name.");
            return false;
        }
        if (!accountNumber) {
            setAppError(true);
            setAppErrorMessage("Please enter your bank account number.");
            return false;
        }
        if (!bankAccountNumberValidation(accountNumber)) {
            setAppError(true);
            setAppErrorMessage("Invalid bank account number.");
            return false;
        }
        if (!ifscCode) {
            setAppError(true);
            setAppErrorMessage("Please enter your IFSC code.");
            return false;
        }
        return true; // Return true if no errors
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!validateForm()) {
            return; // Stop execution if there are validation errors
        }

        try {
            setIsLoading(true);
            // Call the update function or API here
            console.log("Form submitted successfully!");
            
            alert("Bank details updated successfully!");
            window.location.href = "/profilemenu"; // Redirect after successful update
        } catch (error) {
            setAppError(true);
            setAppErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <MenuSide />

            <section className="section-b-space">
                <div className="custom-container">
                    <form className="auth-form" onSubmit={handleSubmit}>
                        <h4>Bank Details</h4>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Account Name</label>
                                    <input 
                                        type="text" 
                                        className="form-control shadow-none" 
                                        placeholder="Enter Your Bank Account Name" 
                                        value={accountName} 
                                        onChange={(e) => setAccountName(e.target.value)} 
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Account Number</label>
                                    <input 
                                        type="number" 
                                        className="form-control shadow-none" 
                                        placeholder="Enter Your Bank Account Number" 
                                        value={accountNumber} 
                                        onChange={(e) => setAccountNumber(e.target.value)} 
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">IFSC</label>
                                    <input 
                                        type="text" 
                                        className="form-control shadow-none" 
                                        placeholder="Enter Your Bank IFSC" 
                                        value={ifscCode} 
                                        onChange={(e) => setIfscCode(e.target.value)} 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="fixed-btn">
                            <div className="custom-container">
                                <div className="d-flex align-items-center flex-nowrap gap-2">
                                    <Link to="/profilemenu" className="btn gray-btn mt-0 w-50">Go Back</Link>
                                    <button type="submit" className="btn theme-btn mt-0 w-50">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                
            </section>
        </>
    );
}
