import axios from 'axios';
import config from '../config';

const Search = async (kitchenId,page, count, keyword) => {
    try {
        const token = localStorage.getItem("oojwt");
        const response = await axios.get(config.apiUrl + 'kitchen-menu/search?kitchen_id='+kitchenId+'page='+page+'&count='+count+'&keyword=' + keyword, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        if( response.status === 200 ){
            return {
                status: 200,
                kitchenMenus: response.data.kitchenMenus
            };
        }
    } catch (error) {
        console.log(error);
        if( error.response.status === 401 ){
            return {
                status: 401,
                message: error.response.data.message
            };
        }
    }
}

const GetById = async (id) => {
    try {
        const token = localStorage.getItem("oojwt");
        const response = await axios.get(config.apiUrl + 'kitchen-menu/get-by-id?id=' + id, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });

        //console.log("test :"+response.data);
        if (response.status === 200) {
            return {
                status: 200,
                kitchenMenu: response.data.kitchenMenu
            };
        }
    } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
            return {
                status: 401,
                message: error.response.data.message
            };
        }
    }
}

//create a function to send the name and image to axios post to save the cateogry
const Add = async (kitchenId, foodId, foodCategoryId, foodCusineId, image, foodDietIds, foodTimeIds, foodAllergyIds, name, descriptionShort, descriptionLong, ingredient, price, discountPercentage, taxPercentage, preparationMinutes, status) =>{
    try {
        const data = new FormData();
        data.append('kitchen_id', kitchenId);
        console.log("kitchenId: "+kitchenId);
        data.append('food_id', foodId);
        data.append('food_category_id', foodCategoryId);
        data.append('food_cusine_id', foodCusineId);
        data.append('image', image);

        foodDietIds.forEach((temp) => {
            data.append('food_diet_ids[]', temp);
        });

        foodTimeIds.forEach((temp) => {
            data.append('food_time_ids[]', temp);
        });

        foodAllergyIds.forEach((temp) => {
            if (temp.id !== undefined) {
                data.append('food_allergy_ids[]', temp);
            }
        });

        data.append('name', name);
        data.append('description_short', descriptionShort);
        data.append('description_long', descriptionLong);
        data.append('ingredient', ingredient);
        data.append('price', price);
        data.append('discount_percentage', discountPercentage);
        data.append('tax_percentage', taxPercentage);
        data.append('preparation_minutes', preparationMinutes);
        data.append('status', status);

        const token = localStorage.getItem("oojwt");
        const response = await axios.post(config.apiUrl + 'kitchen-menu/add', data, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'multipart/form-data'
            }
        });

        console.log(response.data);
        if (response.status === 200) {
            return {
                status: 200,
                message: response.data.message,
                kitchenMenu: response.data.kitchenMenu
            };
        }
    } catch (error) {
        console.log(error);
        return {
            status: 401,
            message: error.response?.data?.message || "An error occurred"
        };
    }
};


const Update = async (id, kitchenId, foodId, foodCategoryId, foodCusineId, image, foodDietIds, foodTimeIds, foodAllergyIds, name, descriptionShort, descriptionLong, ingredient, price, discountPercentage, taxPercentage, preparationMinutes, status) => {
    try {
       
        const data = new FormData();
        data.append("id", id);
        data.append('kitchen_id', kitchenId);
        data.append('food_id', foodId);
        data.append('food_category_id', foodCategoryId);
        data.append('food_cusine_id', foodCusineId);
        data.append('image', image);
        foodDietIds.forEach((temp) => {
            data.append('food_diet_ids[]', temp);
            console.log("dietid : ", temp);
        });
        
        foodTimeIds.forEach((temp) => {
            data.append('food_time_ids[]', temp);
        });
        foodAllergyIds.forEach((temp) => {
            console.log("allid : ", temp);
            data.append('food_allergy_ids[]', temp);
        });
        
        data.append('name', name);
        data.append('description_short', descriptionShort);
        data.append('description_long', descriptionLong);
        data.append('ingredient', ingredient);
        data.append('price', price);
        data.append('discount_percentage', discountPercentage);
        data.append('tax_percentage', taxPercentage);
        data.append('preparation_minutes', preparationMinutes);
        data.append('status', status);
        const token = localStorage.getItem("oojwt");
        const response = await axios.post(config.apiUrl + 'kitchen-menu/update', data, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'multipart/form-data'
            }
        });
        console.log("response=="+response);
        if (response.status === 200) {
      
            return {
                status: 200,
                message: response.data.message,
                kitchenMenu: response.data.kitchenMenu
            };
        }
    } catch (error) {
        console.log("error : ", error);
        return {
            status: error.response.status,
            message: error.response.data.message
        };
    }
}

const GetByKitchenId = async (kitchen_id) => {
    try {
        const token = localStorage.getItem("oojwt");
        const response = await axios.get(config.apiUrl + 'kitchen-menu/get-by-kitchen-id?kitchen_id=' + kitchen_id, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });

        console.log('GetByKitchenId Response:', response.data);

        if (response.status === 200) {
            return {
                status: 200,
                kitchenMenus: response.data.kitchenMenus || []
            };
        }
    } catch (error) {
        console.error("Error fetching kitchen menus:", error);

        return {
            status: error.response?.status || 500,
            message: error.response?.data?.message || "An error occurred",
            kitchenMenus: []
        };
    }
};


export { Add, Update, GetById, Search, GetByKitchenId };