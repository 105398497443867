import axios from 'axios';
import config from '../config';

const GetByKitchenId = async (kitchenId) => {
    try {
        const token = localStorage.getItem("oojwt");
        const response = await axios.get(config.apiUrl + 'invoice/get-by-kitchen-id?id=' + kitchenId, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        console.log(response.data);
        if (response.status === 200) {
            return {
                status: 200,
                invoices: response.data.invoices,
                overallKitchenShare: response.data.overallKitchenShare,
                overallPartnerShare: response.data.overallPartnerShare
            };
        }
    } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
            return {
                status: 401,
                message: error.response.data.message
            };
        }
    }
}

const GetById = async (id) => {
    try {
        const token = localStorage.getItem("oojwt");
        const response = await axios.get(config.apiUrl + 'invoice/get-by-id?id=' + id, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        console.log(response.data);
        if (response.status === 200) {
            return {
                status: 200,
                invoice: response.data.invoice,
                kitchenShare: response.data.kitchenShare,
                portalShare: response.data.portalShare,
                orderMenus: response.data.orderMenus
            };
        }
    } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
            return {
                status: 401,
                message: error.response.data.message
            };
        }
    }
}

const SumulateInvoice = async (kitchen_id) => {
    try {
        const token = localStorage.getItem("oojwt");
        const response = await axios.get(config.apiUrl + 'kitchen-menu/make-random-order?kitchen_id=' + kitchen_id, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        console.log(response.data);
        if (response.status === 200) {
            return {
                status: 200,
                order: response.data.order
            };
        }
    } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
            return {
                status: 401,
                message: error.response.data.message
            };
        }
    }
}

export { GetById, GetByKitchenId, SumulateInvoice };