import { Link } from "react-router-dom";
import ProfileMenu from "./ProfileMenu";

export default function MenuFooter() {

    return (
        <>

            <section className="panel-space"></section>

            <div className="navbar-menu">
                <ul>
                    <li className="active w-25">
                        <Link to="/dashboard" className="icon">
                            <img className="unactive" src="/assets/system/icons/home.png" alt="home" />
                            <img className="active" src="/assets/system/icons/home.png" alt="home" />
                            <span>Home</span>
                        </Link>
                    </li>
                    <li className="w-25">
                        <Link to="/ordersearch/new" className="icon">

                            <img className="unactive" src="/assets/system/icons/order.png" alt="receipt" />
                            <img className="active" src="/assets/system/icons/order.png" alt="receipt" />
                            <span>Order</span>
                        </Link>
                    </li>
                    <li className="w-25">
                        <Link to="/wallet" className="icon">

                            <img className="unactive" src="/assets/system/icons/wallet.png" alt="earning" />
                            <img className="active" src="/assets/system/icons/wallet.png" alt="earning" />
                            <span>wallet</span>
                        </Link>
                    </li>

                    <li className="w-25">
                        <Link to="/profilemenu" className="icon">

                            <img className="unactive" src="/assets/system/icons/profile.png" alt="user" />
                            <img className="active" src="/assets/system/icons/profile.png" alt="user" />
                            <span>Profile</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    );
}