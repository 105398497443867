import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MenuSide from "./MenuSide";
import { useGlobalContext } from "./GlobalContext";
import {
  validateTextField,
  CouponCode,
  PositiveNumber,
  MaxDiscount,
  radioValidation,
  UsageLimit,
} from "./service/Validation"; 

export default function CouponAdd() {
  const { setIsLoading, setAppError, setAppErrorMessage } = useGlobalContext();

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [code, setCode] = useState("");
  const [minPurchase, setMinPurchase] = useState("");
  const [discount, setDiscount] = useState("");
  const [couponType, setCouponType] = useState("percentage");
  const [maxDiscount, setMaxDiscount] = useState(""); 
  const [usageLimitPerBuyer, setUsageLimitPerBuyer] = useState("");
  const [overallUsageLimit, setOverallUsageLimit] = useState("");
  const [buyerType, setBuyerType] = useState('');
const handleRadioChange = (e) => {
        setBuyerType(e.target.value);
    };

  const saveCoupon = async () => {
    // Comprehensive validation checks
    if (title === "" || !validateTextField(title)) {
      setAppError(true);
      setAppErrorMessage(
        "Title must be at least 3 characters long and contain no special characters."
      );
      return;
    }

    if (subtitle === "" || !validateTextField(subtitle)) {
      setAppError(true);
      setAppErrorMessage(
        "Subtitle must be at least 3 characters long and contain no special characters."
      );
      return;
    }

    if (code === "" || !CouponCode(code)) {
      setAppError(true);
      setAppErrorMessage(
        "Coupon code must be alphanumeric and at least 4 characters long."
      );
      return;
    }
    if (startDate === "") {
        setAppError(true);
        setAppErrorMessage("Start date is required.");
        return;
    }
    
    if (endDate === "") {
        setAppError(true);
        setAppErrorMessage("End date is required.");
        return;
    }

    if (minPurchase === "" || !PositiveNumber(minPurchase)) {
      setAppError(true);
      setAppErrorMessage("Min purchase must be a positive number.");
      return;
    }

    if (discount === "" || !PositiveNumber(discount)) {
      setAppError(true);
      setAppErrorMessage("Discount must be a positive number.");
      return;
    }

    if (maxDiscount === "" || !MaxDiscount(maxDiscount)) {
      setAppError(true);
      setAppErrorMessage("Max discount must be a positive number.");
      return;
    }

    if (!buyerType || !radioValidation(buyerType)) {
      setAppError(true);
      setAppError(true);
      setAppErrorMessage(
        "Buyer type must be either 'First Time Buyer' or 'All Buyers'."
      );
      return;
    }
    

    if (usageLimitPerBuyer === "" || !UsageLimit(usageLimitPerBuyer)) {
      setAppError(true);
      setAppErrorMessage("Usage limit per buyer must be a positive integer.");
      return;
    }

    if (overallUsageLimit === "" || !UsageLimit(overallUsageLimit)) {
      setAppError(true);
      setAppErrorMessage("Overall usage limit must be a positive integer.");
      return;
    }

    // Proceed with coupon creation
    try {
      setIsLoading(true);
      
      // Implement actual coupon creation logic here
      alert("Coupon creation logic");
      window.location.href = "/profilemenu"; 
    } catch (error) {
      setAppError(true);
      setAppErrorMessage("Failed to create coupon");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <MenuSide />
      <section className="section-b-space">
        <div className="custom-container">
          <div className="header">
            <div className="head-content">
              <h2>Create New Coupon</h2>
            </div>
            <Link to="/couponlist">
              <i className="ri-list-check"></i>
            </Link>
          </div>
          <form className="auth-form">
            <div className="row">
              {/* Title Input */}
              <div className="col-12">
                <div className="form-group">
                  <label className="form-label fw-semibold">Title</label>
                  <input
                    type="text"
                    className="form-control shadow-none"
                    placeholder="Enter name"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>

              {/* Subtitle Input */}
              <div className="col-12">
                <div className="form-group mt-3">
                  <label className="form-label fw-semibold">Sub Title</label>
                  <input
                    type="text"
                    className="form-control shadow-none"
                    placeholder="Enter sub title"
                    value={subtitle}
                    onChange={(e) => setSubtitle(e.target.value)}
                  />
                </div>
              </div>

              {/* Code Input */}
              <div className="col-12">
                <div className="form-group mt-3">
                  <label className="form-label fw-semibold">Code</label>
                  <input
                    type="text"
                    className="form-control shadow-none"
                    placeholder="Enter code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
              </div>
              {/* Minimum Purchase Input */}
              <div className="col-12">
                <div className="form-group mt-3">
                  <label className="form-label fw-semibold">Min Purchase</label>
                  <input
                    type="number"
                    className="form-control shadow-none"
                    placeholder="Enter min purchase"
                    value={minPurchase}
                    onChange={(e) => setMinPurchase(e.target.value)}
                  />
                </div>
              </div>
              {/* Date Inputs */}
              <div className="col-6">
                <div className="form-group mt-3">
                  <label className="form-label fw-semibold">Start Date</label>
                  <input type="date" className="form-control shadow-none" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mt-3">
                  <label className="form-label fw-semibold">End Date</label>
                  <input type="date" className="form-control shadow-none" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>
              </div>

              {/* Discount and Coupon Type */}
              <div className="col-6">
                <div className="form-group mt-3">
                  <label className="form-label fw-semibold">Discount</label>
                  <input
                    type="number"
                    className="form-control shadow-none"
                    placeholder="Enter discount"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mt-3">
                  <label className="form-label fw-semibold">Coupon Type</label>
                  <select
                    className="form-select shadow-none py-3"
                    value={couponType}
                    onChange={(e) => setCouponType(e.target.value)}
                  >
                    <option value="percentage">Percentage</option>
                    <option value="amount">Amount</option>
                  </select>
                </div>
              </div>

              {/* Max Discount Input */}
              <div className="col-12">
                <div className="form-group mt-3">
                  <label className="form-label fw-semibold">
                    Max Discount As Amount
                  </label>
                  <input
                    type="number"
                    className="form-control shadow-none"
                    placeholder="Enter max discount"
                    value={maxDiscount}
                    onChange={(e) => setMaxDiscount(e.target.value)}
                  />
                </div>
              </div>

              {/* Buyer Type Input */}
              <div className="col-12">
                <div className="form-group mt-3">
                  <label className="form-label fw-semibold">For Whom</label>
                  <div className="form-check mb-2">
                  <input
                                className="form-check-input"
                                type="radio"
                                name="buyerType"
                                id="buyerType1"
                                value="1"
                                checked={buyerType === '1'}
                                onChange={handleRadioChange}
                            />
                    <label
                      className="form-check-label dark-text"
                      htmlFor="buyerType1"
                    >
                      First Time Buyer
                    </label>
                  </div>
                  <div className="form-check mb-2">
                  <input
                                className="form-check-input"
                                type="radio"
                                name="buyerType"
                                id="buyerType2"
                                value="0"
                                checked={buyerType === '0'}
                                onChange={handleRadioChange}
                            />
                    <label
                      className="form-check-label dark-text"
                      htmlFor="buyerType2"
                    >
                      All Buyers
                    </label>
                  </div>
                </div>
              </div>

              {/* Usage Limit Per Buyer Input */}
              <div className="col-12">
                <div className="form-group mt-3">
                  <label className="form-label fw-semibold">
                    How many times a buyer can use this
                  </label>
                  <input
                    type="number"
                    className="form-control shadow-none"
                    placeholder="Enter max usage / buyer"
                    value={usageLimitPerBuyer}
                    onChange={(e) => setUsageLimitPerBuyer(e.target.value)}
                  />
                </div>
              </div>

              {/* Overall Usage Limit Input */}
              <div className="col-12">
                <div className="form-group mt-3">
                  <label
                    htmlFor="overallUsageLimit"
                    className="form-label fw-semibold"
                  >
                    How many it can be used overall
                  </label>
                  <input
                    type="number"
                    id="overallUsageLimit"
                    name="overallUsageLimit"
                    className="form-control shadow-none"
                    placeholder="Enter max usage count"
                    value={overallUsageLimit}
                    onChange={(e) => setOverallUsageLimit(e.target.value)}
                  />
                </div>
              </div>

              {/* Submit Button */}
              <div>
                <button
                  type="button"
                  className="btn theme-btn w-100" 
                  onClick={saveCoupon}
                >
                  Create Coupon
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
