import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
//import GlobalContext
import { useGlobalContext } from './GlobalContext';

export default function MenuSide() {

    const navigate = useNavigate();
    const { isLoading, setIsLoading, isAppError, setAppError, appErrorMessage, setAppErrorMessage, appErrorTitle, setAppErrorTitle, appErrorMode, setAppErrorMode, appUser, setAppUser, isLogin, setIsLogin, isLogoutRequest, setIsLogoutRequest, appKitchen } = useGlobalContext();

    const handleNavigation = (path) => {
        // Navigate to the desired path
        navigate(path);
    };

    return (
        <>
            <header className="top-menu">
                <div className="custom-container">
                    <div className="header">
                        <div className="head-content">
                            <button className="sidebar-btn" type="button" data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasLeft">
                                <i className="ri-menu-line"></i>
                            </button>
                            <h2>{appKitchen && appKitchen.name}</h2>
                        </div>
                    </div>
                </div>
            </header>
            <div className="offcanvas sidebar-offcanvas offcanvas-start" tabIndex="-1" id="offcanvasLeft">
                <div className="offcanvas-header">
                    <h3>Daily Activities</h3>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="sidebar-content">
                        <ul className="link-section">

                            <li>
                                <button className="pages w-100" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => handleNavigation('/ordersearch/new')}>
                                    <h3>New Orders</h3>
                                    <i className="ri-arrow-drop-right-line"></i>
                                </button>
                            </li>

                            <li>
                                <button className="pages w-100" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => handleNavigation('/ordersearch/progress')}>
                                    <h3>In Progress Orders</h3>
                                    <i className="ri-arrow-drop-right-line"></i>
                                </button>
                            </li>

                            <li>
                                <button className="pages w-100" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => handleNavigation('/ordersearch/ready')}>
                                    <h3>Ready Orders</h3>
                                    <i className="ri-arrow-drop-right-line"></i>
                                </button>
                            </li>

                            <li>
                                <button className="pages w-100" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => handleNavigation('/ordersearch/onroad')}>
                                    <h3>On Road Orders</h3>
                                    <i className="ri-arrow-drop-right-line"></i>
                                </button>
                            </li>

                            <li>
                                <button className="pages w-100" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => handleNavigation('/ordersearch/completed')}>
                                    <h3>Completed Orders</h3>
                                    <i className="ri-arrow-drop-right-line"></i>
                                </button>
                            </li>

                            <li className="d-none">
                                <Link to="" className="pages" data-bs-dismiss="offcanvas" aria-label="Close">
                                    <h3>My Feedbacks</h3>
                                    <i className="ri-arrow-drop-right-line"></i>
                                </Link>
                            </li>

                            <li className="d-none">
                                <button className="pages w-100" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => handleNavigation('/couponadd')}>
                                    <h3>Kitchen Coupons</h3>
                                    <i className="ri-arrow-drop-right-line"></i>
                                </button>
                            </li>

                            <li>
                                <Link to="/brandofficier" className="pages w-100" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => handleNavigation('/brandofficier')}>
                                    <h3 className="text-danger">My Brand Officier</h3>
                                    <i className="ri-arrow-drop-right-line"></i>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
        </>
    );
}