
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { useGlobalContext } from './GlobalContext';
import { validatePhoneNumber } from "./service/Validation";
import { UserLogin } from "./service/UserService";

//create function component as login
export default function LoginOtp() {

    const { isLoading, setIsLoading, isAppError, setAppError, appErrorMessage, setAppErrorMessage, appErrorTitle, setAppErrorTitle, appErrorMode, setAppErrorMode, appUser, setAppUser, isLogin, setIsLogin, isLogoutRequest, setIsLogoutRequest } = useGlobalContext();

    const { mobile } = useParams();
    const [otp, setOtp] = useState("");

    const verifyOtp = async () => {

        if (mobile === "" || otp === "") {
            setAppError(true);
            setAppErrorMessage("Please enter the password");
            setAppErrorTitle("Form Validation Error");
            setAppErrorMode("error");
            return;
        }
        else if (validatePhoneNumber(mobile) === false) {
            setAppError(true);
            setAppErrorMessage("Please enter valid 10 digit mobile number");
            setAppErrorTitle("Form Validation Error");
            setAppErrorMode("error");
            return;
        }

        setIsLoading(true);
        var response = await UserLogin(mobile, otp);
        if (response.status === 200) {
            setAppError(true);
            setAppErrorMessage("Login success");
            setAppErrorTitle("Access Granted");
            setAppErrorMode("success");
            window.location.href = "/dashboard";
        }
        else {
            setAppError(true);
            setAppErrorMessage("Invalid login details");
            setAppErrorTitle("Access Prohibited");
            setAppErrorMode("error");
        }
        setIsLoading(false);
    }

    return (

        <>
            <header className="section-t-space">
                <div className="custom-container">
                    <div className="header-panel">
                        <a href="/login">
                            <i className="ri-arrow-left-s-line"></i>
                        </a>
                        <h2>OTP Verification</h2>
                    </div>
                </div>
            </header>

            <div className="custom-container">
                <img src="/assets/system/app-page-3.png" alt="login-img" className="img-fluid welcome-img" />
                <div className="otp-verification">
                    <h3>We have sent a verification code to</h3>
                    <h3 className="otp-number mt-2">{mobile}</h3>
                </div>
                <div className="auth-form mt-1">
                    <div className="form-group mt-4">
                        <div className="d-flex gap-3">
                            <div className="form-input dark-border-gradient">
                                <input type="number" className="form-control" placeholder="6 digit OTP" maxLength={6} minLength={6} value={otp} onChange={(e) => setOtp(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <button type="button" onClick={(e) => verifyOtp()} className="btn theme-btn w-100 mt-4" role="button">Verify OTP</button>
                </div>
            </div>
        </>
    );
}