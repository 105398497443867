import { Link } from "react-router-dom";
import MenuSide from "./MenuSide";
import { useGlobalContext } from "./GlobalContext";
import { useState } from "react";
import { useEffect } from 'react';
import KitchenFunctions from "./service/KitchenService";
import { nameValidation, pincodeValidation, addressValidation, bioValidation } from './service/Validation';

//create function component as login
export default function ProfileUpdate() {

    const { isLoading, setIsLoading, isAppError, setAppError, appErrorMessage, setAppErrorMessage, appErrorTitle, setAppErrorTitle, appErrorMode, setAppErrorMode, appUser, setAppUser, isLogin, setIsLogin, isLogoutRequest, setIsLogoutRequest, appKitchen } = useGlobalContext();

    const [name, setName] = useState("");
    const [bio, setBio] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [landmark, setLandmark] = useState("");
    const [pincode, setPincode] = useState("");

    
    const updateDetails = async () => {

    };

    return (
        <>
            <MenuSide />

            <section className="section-b-space">
                <div className="custom-container">
                    <div className="auth-form" target="_blank">
                        <h4>Basic Details</h4>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Kitchen Name</label>
                                    <input type="text" className="form-control shadow-none" placeholder="Enter restaurant name" value={appKitchen.name} onChange={(e) => setName(e.target.value)} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Bio</label>
                                    <textarea type="text" className="form-control shadow-none" rows="3"
                                        placeholder="Enter description" value={appKitchen.bio} onChange={(e) => setBio(e.target.value)}></textarea>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Address Line 1</label>
                                    <input type="text" className="form-control shadow-none"
                                        placeholder="Enter restaurant address line 1"  value={appKitchen.addressLine1} onChange={(e) => setAddressLine1(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Address Line 2</label>
                                    <input type="text" className="form-control shadow-none"
                                        placeholder="Enter restaurant address line 2" value={appKitchen.addressLine2} onChange={(e) => setAddressLine2(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">City</label>
                                    <input type="text" className="form-control shadow-none" placeholder="Enter city" readOnly value={appKitchen.user.city.name} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Pincode</label>
                                    <input type="number" className="form-control shadow-none" placeholder="Enter Pincode" value={appKitchen.pincode} onChange={(e) => setPincode(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fixed-btn">
                    <div className="custom-container">
                        <div className="d-flex align-items-center flex-nowrap gap-2">
                            <Link to="/profilemenu" className="btn gray-btn mt-0 w-100">Back</Link>
                            <button type="button" className="btn theme-btn mt-0 w-100 d-none" onClick={updateDetails}>Save</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}