import { Link, useParams } from "react-router-dom";
import MenuSide from "./MenuSide";
import MenuFooter from "./MenuFooter";

import { useState, useEffect } from "react";
import { useGlobalContext } from "./GlobalContext";
import { bioValidation, discountValidation, imageValidation, priceValidation, taxValidation, radioValidation, minUnitValidation, maxUnitValidation } from "./service/Validation";
import { GetById, Search } from "./service/KitchenMenuService";
import config from "./config";

//create function component as login
export default function KitchenMenuAdd() {
    const { isLoading, setIsLoading, isAppError, setAppError, appErrorMessage, setAppErrorMessage, appErrorTitle, setAppErrorTitle, appErrorMode, setAppErrorMode, appUser, setAppUser, isLogin, setIsLogin, isLogoutRequest, setIsLogoutRequest, appKitchen } = useGlobalContext();

    const { menuId } = useParams();

    const [kitchenMenu, setKitchenMenu] = useState(null);

    useEffect(() => {
        if (menuId != null && menuId != undefined && menuId != "") {
            fetchKitchenMenu();
        }
    }, []);

    const fetchKitchenMenu = async () => {
        setIsLoading(true);
        try {
            const response = await GetById(menuId);
            // console.log("fetch datas:"+response.kitchenMenu);
            if (response.status === 200) {
                const menu = response.kitchenMenu;
                console.log("fetch data of menu :", menu);
                setKitchenMenu(menu);
            }
        } catch (error) {
            setAppError(true);
            setAppErrorMessage('Failed to load kitchen menu data');
            setAppErrorTitle('Error');
            setAppErrorMode('error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <MenuSide />

            <section className="section-b-space">
                <div className="custom-container mt-5">
                    <div className="header d-none">
                        <div className="head-content">
                            <h2>Create New Menu</h2>
                        </div>
                        <Link to="/kitchenmenulist">
                            <i className="ri-list-check"></i>
                        </Link>
                    </div>
                    <form className="auth-form" target="_blank">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <center>
                                        <img
                                            src={`${config.apiUrl}uploads/kitchen/menu/${kitchenMenu.id}.${kitchenMenu.image}`}
                                            alt="Menu Item"
                                            style={{ height: '150px', width: '150px', objectFit: 'cover' }}
                                        />
                                    </center>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label fw-semibold">Name</label>
                                    <input type="text" className="form-control shadow-none" placeholder="Enter name" value={kitchenMenu && kitchenMenu.name} readOnly />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-2">
                                    <label className="form-label fw-semibold">Category</label>
                                    <input type="text" className="form-control shadow-none" placeholder="Enter name" value={kitchenMenu && kitchenMenu.foodCategory.name} readOnly />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-2">
                                    <label className="form-label fw-semibold">Cusine</label>
                                    <input type="text" className="form-control shadow-none" placeholder="Enter name" value={kitchenMenu && kitchenMenu.foodCusine.name} readOnly />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-2">
                                    <label className="form-label fw-semibold">Food Type</label>
                                    <input type="text" className="form-control shadow-none" placeholder="Enter name" value={kitchenMenu && kitchenMenu.food.name} readOnly />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-2">
                                    <label className="form-label fw-semibold">Preparation Minutes</label>
                                    <input type="text" className="form-control shadow-none" placeholder="Enter name" value={kitchenMenu && kitchenMenu.preparationMinutes} readOnly />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-2">
                                    <label className="form-label fw-semibold">Cusine</label>
                                    <input type="text" className="form-control shadow-none" placeholder="Enter name" value={kitchenMenu && kitchenMenu.foodCusine.name} readOnly />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-2">
                                    <label className="form-label fw-semibold">Description Short</label>
                                    <textarea readOnly type="text" className="form-control shadow-none" placeholder="Enter name">
                                        {kitchenMenu && kitchenMenu.descriptionShort}
                                    </textarea>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-2">
                                    <label className="form-label fw-semibold">Description Long</label>
                                    <textarea readOnly type="text" className="form-control shadow-none" placeholder="Enter name">
                                        {kitchenMenu && kitchenMenu.descriptionLong}
                                    </textarea>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-2">
                                    <label className="form-label fw-semibold">Ingredients</label>
                                    <textarea readOnly type="text" className="form-control shadow-none" placeholder="Enter name">
                                        {kitchenMenu && kitchenMenu.ingredient}
                                    </textarea>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-2">
                                    <label className="form-label fw-semibold">Price</label>
                                    <input type="text" className="form-control shadow-none" placeholder="Enter name" value={kitchenMenu && kitchenMenu.price} readOnly />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-2">
                                    <label className="form-label fw-semibold">Discont</label>
                                    <input type="text" className="form-control shadow-none" placeholder="Enter name" value={kitchenMenu && kitchenMenu.discountPercentage + " %"} readOnly />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-2">
                                    <label className="form-label fw-semibold">Tax</label>
                                    <input type="text" className="form-control shadow-none" placeholder="Enter name" value={kitchenMenu && kitchenMenu.taxPercentage + " %"} readOnly />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <Link to="/kitchenmenulist" className="btn gray-btn mt-0 w-100">Go Back</Link>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <button type="button" className="d-none btn theme-btn mt-0 w-100">Add</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
            <MenuFooter />
        </>
    );
}