//write a function to validate a string is having 10 digits none other than number
function validatePhoneNumber(phoneNumber) {
    const regex = /^\d{10}$/;
    return regex.test(phoneNumber);
}

//write a function to validate string whethere it is having proper email format
function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

export function emailValidation(email) {
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function passwordValidation(password) {
    var passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
}

//pan document upload validation


export function mobileValidation(mobile) {
    // Remove any spaces from the input before validating
    mobile = mobile.trim();

    // Validate mobile with optional country code (e.g., +91 for India or without country code)
    const mobileRegex = /^(?:\+?\d{1,3})?(\d{10})$/;

    return mobileRegex.test(mobile);
}


export function whatsappValidation(mobile) {
    var mobileRegex = /^\+?[1-9]\d{1,14}$/;
    return mobileRegex.test(mobile);
}

//here  i want name validation , if i want to enter more than 2 charecter and dont allow special charectors , its allow only '
export function nameValidation(name) {
    var nameRegex = /^[a-zA-Z']{3,}$/;
    return nameRegex.test(name);
}

// here i want to validate 6 digits pincode
export function pincodeValidation(pincode) {
    var pincodeRegex = /^\d{6}$/;
    return pincodeRegex.test(pincode);
}

//here i want date validation , if i give 31-12-2024, 12-31-2024, 2024-12-31  its accept and store database format validation
export function dateValidation(date) {
    var dateRegex = /^(0?[1-9]|[12][0-9]|3[01])[-\/](0?[1-9]|1[012])[-\/]\d{4}$|^(0?[1-9]|1[012])[-\/](0?[1-9]|[12][0-9]|3[01])[-\/]\d{4}$|^\d{4}[-\/](0?[1-9]|1[012])[-\/](0?[1-9]|[12][0-9]|3[01])$/;
    return dateRegex.test(date);
}


//here i want aadhar card num validation
export function aadharValidation(aadhar) {
    var aadharRegex = /^\d{12}$/;
    return aadharRegex.test(aadhar);
}

//here i want pancard validation
export function panValidation(pan) {
    var panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return panRegex.test(pan);
}

//here  i want latitude dms amd decimal validation
export function latitudeValidation(latitude) {
    var decimalRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
    var dmsRegex = /^(\d{1,2})°(\d{1,2})'(\d{1,2}(\.\d+)?)["]?[NS]?$/;
    return decimalRegex.test(latitude) || dmsRegex.test(latitude);
}


export function longitudeValidation(longitude) {
    var decimalRegex = /^[-+]?((1[0-7]\d|[1-9]?\d)(\.\d+)?|180(\.0+)?)$/;
    var dmsRegex = /^(\d{1,3})°(\d{1,2})'(\d{1,2}(\.\d+)?)["]?[EW]?$/;
    return decimalRegex.test(longitude) || dmsRegex.test(longitude);
}

//here i want gst validation
export function gstValidation(gst) {
    var gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1}$/;
    return gstRegex.test(gst);
}

// Door Number Validation (Alphanumeric and '/')
export function doorNumberValidation(doorNumber) {
    return /^[A-Za-z0-9/ ]+$/.test(doorNumber);
}

// Name Validation (At least 2 characters)
export function bioValidation(name) {
    return name.trim().length >= 5;
}


// Address Validation (At least 10 characters)
export function addressValidation(address) {
    return address.trim().length >= 10;
}

// Landmark Validation (Optional, but at least 3 characters if provided)
export function landmarkValidation(landmark) {
    return !landmark || landmark.trim().length >= 3;
}



export function fssaiValidation(fssai) {
    // Regex for alphanumeric string of exactly 14 characters
    var fssaiRegex = /^[A-Za-z0-9]{14}$/;
    return fssaiRegex.test(fssai);
}
//export all the above functions
export { validatePhoneNumber, validateEmail };

export function imageValidation(fileName) {
    // Allowed image extensions
    var allowedExtensions = /\.(jpg|jpeg|png|gif|webp|svg|bmp|tiff)$/i;

    // Check if the file name matches the allowed extensions
    return allowedExtensions.test(fileName);
}

export function panDocumentValidation(fileName) {
    // Allowed document extensions for PAN card upload
    var allowedExtensions = /\.(jpg|jpeg|pdf)$/i;

    // Check if the file name matches the allowed extensions
    return allowedExtensions.test(fileName);
}

export function priceValidation(price) {
    // Regex for valid price format (positive number with up to two decimal points)
    var priceRegex = /^\d+(\.\d{1,2})?$/;
    return priceRegex.test(price);
}

export function taxValidation(taxPercentage) {
    // Check if tax percentage is a number between 0 and 100
    return !isNaN(taxPercentage) && taxPercentage >= 0 && taxPercentage <= 100;
}
export function minUnitValidation(minUnit) {
    // Check if discount percentage is a number between 0 and 100
    return !isNaN(minUnit) && minUnit >= 1 && minUnit <= 10;
}
export function maxUnitValidation(maxUnit) {
    // Check if discount percentage is a number between 0 and 100
    return !isNaN(maxUnit) && maxUnit >= 1 && maxUnit <= 10;
}
export function discountValidation(discountPercentage) {
    // Check if discount percentage is a number between 0 and 100
    return !isNaN(discountPercentage) && discountPercentage >= 0 && discountPercentage <= 100;
}
export function radioValidation(radioValue) {
    // Check if the value is either '0' or '1'
    return radioValue === '0' || radioValue === '1';
}

// Function to validate bank account name (at least 3 characters and allows letters and spaces)
export function bankAccountNameValidation(accountName) {
    // Allows letters, spaces, and periods; must be at least 3 characters long
    const accountNameRegex = /^[a-zA-Z\s.]{3,}$/;
    return accountNameRegex.test(accountName);
}
// Function to validate bank account number (exactly 10 digits)
export function bankAccountNumberValidation(accountNumber) {
    // Assuming standard bank account numbers are numeric and can vary in length; here we assume 10 digits for simplicity
    const accountNumberRegex = /^\d{10}$/;
    return accountNumberRegex.test(accountNumber);
}
// Validate Title or Subtitle (at least 3 characters, no special characters except spaces)
export function validateTextField(title) {
    const textRegex = /^[a-zA-Z ]{3,}$/;
    return textRegex.test(title);
}
// Validate Coupon Code (alphanumeric with optional hyphens/underscores)
export function CouponCode(couponCode) {
    const codeRegex = /^[a-zA-Z0-9-_]{3,}$/;
    return codeRegex.test(couponCode);
}
//  Min Purchase or Discount (positive number)
export function PositiveNumber(value) {
    return !isNaN(value) && value > 0;
}
//  Coupon Type (either 'amount' or 'percentage')
export function CouponType(couponType) {
    return couponType === 'amount' || couponType === 'percentage';
}
//  Max Discount (positive number)
export function MaxDiscount(discounAmount) {
    return PositiveNumber(discounAmount);
}
//  Usage Limit (positive integer)
export function UsageLimit(userLimit) {
    return !isNaN(userLimit) && userLimit > 0;
}