import { Link } from "react-router-dom";
import MenuSide from "./MenuSide";
import { useGlobalContext } from "./GlobalContext";
import { useState, useEffect } from "react";
import { fssaiValidation, gstValidation, panValidation, panDocumentValidation } from './service/Validation';
import config from "./config";

export default function ProfileKycUpdate() {
    const { isLoading, setIsLoading, isAppError, setAppError, appErrorMessage, setAppErrorMessage, appErrorTitle, setAppErrorTitle, appErrorMode, setAppErrorMode, appUser, setAppUser, isLogin, setIsLogin, isLogoutRequest, setIsLogoutRequest, appKitchen } = useGlobalContext();

    const [fssaiNumber, setFssaiNumber] = useState(null);
    const [gstNumber, setGstNumber] = useState(null);
    const [panNumber, setPanNumber] = useState(null);
    const [gstDocumentUrl, setGstDocumentUrl] = useState(config.apiUrl + "/uploads/kitchen/gst/" + appKitchen.id + "." + appKitchen.gst);
    const [panDocumentUrl, setPanDocumentUrl] = useState(config.apiUrl + "/uploads/kitchen/pan/" + appKitchen.id + "." + appKitchen.pan);
    const [fssaiDocumentUrl, setFssaiDocumentUrl] = useState(config.apiUrl + "/uploads/kitchen/fssai/" + appKitchen.id + "." + appKitchen.fssai);
    const [panDocument, setPanDocument] = useState(null);


    const handlePanDocumentChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check if the file type is valid
            if (!panDocumentValidation(file.name)) {
                setAppError(true);
                setAppErrorMessage("Invalid file type. Please upload a JPG, PNG, or PDF for PAN document.");
                return;
            }
            setPanDocument(file);
        }
    };

    const getKycData = () => {
        // Start validation only when submitting the form
        if (fssaiNumber === "") {
            setAppError(true);
            setAppErrorMessage("Please enter the FSSAI Number");
            return;
        }

        if (!fssaiValidation(fssaiNumber)) {
            setAppError(true);
            setAppErrorMessage("FSSAI number should be exactly 14 digits long.");
            return;
        }

        if (gstNumber === "") {
            setAppError(true);
            setAppErrorMessage("Please enter the GST Number");
            return;
        }

        if (!gstValidation(gstNumber)) {
            setAppError(true);
            setAppErrorMessage("Please enter a valid GST number in the correct format.");
            return;
        }

        if (panNumber === "") {
            setAppError(true);
            setAppErrorMessage("Please enter the PAN number");
            return;
        }

        if (!panValidation(panNumber)) {
            setAppError(true);
            setAppErrorMessage("Please enter a valid PAN number in the correct format.");
            return;
        }

        if (!panDocument) {
            setAppError(true);
            setAppErrorMessage("Please upload the PAN document.");
            return;
        }

        if (!panDocumentValidation(panDocument.name)) {
            setAppError(true);
            setAppErrorMessage("Invalid file type for PAN document. Only JPG, PNG, or PDF allowed.");
            return;
        }

        alert("Details updated");
        window.location.href = "/profilemenu"; // Redirect after successful validation and submission
    };

    return (
        <>
            <MenuSide />

            <section className="section-b-space">
                <div className="custom-container">
                    <div className="auth-form">
                        <h4>KYC Details - <b>Verified</b></h4>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">FSSAI Number</label>
                                    <input type="text" className="form-control shadow-none" value={appKitchen.fssaiNumber} onChange={(e) => setFssaiNumber(e.target.value)} placeholder=""/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">GST Number</label>
                                    <input type="text" className="form-control shadow-none"  value={appKitchen.gstNumber}
                                        onChange={(e) => setGstNumber(e.target.value)} placeholder=""/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">PAN Number</label>
                                    <input type="text" className="form-control shadow-none"  value={appKitchen.panNumber}
                                        onChange={(e) => setPanNumber(e.target.value)} placeholder="" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">GST Document</label>
                                    <a target="_blank" href={gstDocumentUrl} className="form-control shadow-none">View Document</a>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">PAN Document</label>
                                    <a target="_blank" href={panDocumentUrl} className="form-control shadow-none">View Document</a>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">FSSAI Document</label>
                                    <a target="_blank" href={fssaiDocumentUrl} className="form-control shadow-none">View Document</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fixed-btn">
                    <div className="custom-container">
                        <div className="d-flex align-items-center flex-nowrap gap-2">
                            <Link to="/profilemenu" className="btn gray-btn mt-0 w-100">Go Back</Link>
                            <button onClick={getKycData} className="d-none btn theme-btn mt-0 w-100">Submit</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
