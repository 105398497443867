import { Link } from "react-router-dom";
import MenuFooter from "./MenuFooter";
import MenuSide from "./MenuSide";

//create function component as login
export default function BrandOfficier() {

    return (
        <>
            <MenuSide />

            <br></br>

            <section className="section-t-space">
                <div className="custom-container">
                    <div className="row">
                        <div className="col-3">
                            <img src="../assets/images/logo/72.png" />
                        </div>
                        <div className="col-9">
                            <div className="brand-officier-info">
                                <p>
                                    <b>K.P. Ashok Raj</b>
                                    <br></br>
                                    9:00 AM to 6:00 PM
                                    <br></br>
                                    Chennai Branch
                                </p>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="brand-officier-info">
                                <p>
                                    <br></br>
                                    I have completed Masters in Hotel management. I will be helpful in assisting you organizing your menu, pricing and cooking standardization processes. For any help regarding the process and platform details pleasee feel free to contact me.
                                </p>
                            </div>
                        </div>
                    </div>

                    <hr></hr>

                    <div className="brand-officier-contact">
                        <div className="row">
                            <div className="col-4">
                                <a href="tel:+918799688968" target="_blank" className="icon-box">
                                    <i class="ri-phone-line"></i>
                                    <br></br>
                                    Call Me
                                </a>
                            </div>
                            <div className="col-4">
                                <a href="https://wa.me/919500545437" target="_blank" className="icon-box">
                                    <i class="ri-whatsapp-line"></i>
                                    <br></br>
                                    Chat Me
                                </a>
                            </div>
                            <div className="col-4">
                                <a href="mailto:cmo@oovanoo.com" target="_blank" className="icon-box">
                                    <i class="ri-mail-send-line"></i>
                                    <br></br>
                                    Mail Me
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="brand-info">

                </div>
            </section>

            <MenuFooter />
        </>
    );
}