import { Link } from "react-router-dom";
import MenuSide from "./MenuSide";
import MenuFooter from "./MenuFooter";
import { useGlobalContext } from "./GlobalContext";
import { useEffect, useState } from "react";
import { GetByKitchenId } from "./service/InvoiceService";

//create function component as login
export default function Wallet() {

    const { isLoading, setIsLoading, isAppError, setAppError, appErrorMessage, setAppErrorMessage, appErrorTitle, setAppErrorTitle, appErrorMode, setAppErrorMode, appUser, setAppUser, isLogin, setIsLogin, isLogoutRequest, setIsLogoutRequest, appKitchen } = useGlobalContext();

    const [invoices, setInvoices] = useState(null);
    const [overallKitchenShare, setOverallKitchenShare] = useState(0);
    const [overallPartnerShare, setOverallPartnerShare] = useState(0);

    const fetchKitchen = async () => {
        setIsLoading(true);
        try {

            const responseInvoices = await GetByKitchenId(appKitchen.id);
            if (responseInvoices.status === 200) {
                console.log("fetch data of invoices :", responseInvoices.invoices);
                setOverallKitchenShare(responseInvoices.overallKitchenShare);
                setOverallPartnerShare(responseInvoices.overallPartnerShare);
                setInvoices(responseInvoices.invoices);
            }
        } catch (error) {
            setAppError(true);
            setAppErrorMessage('Failed to load kitchen data');
            setAppErrorTitle('Error');
            setAppErrorMode('error');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchKitchen();
    }, []);

    return (
        <>
            <MenuSide />
            <section class="section-b-space">
                <div class="custom-container mt-5">
                    <div class="earning-card-background withdraw-background">
                        <div class="earning-card-details">
                            <div>
                                <h3>Wallet Balance</h3>
                                <h2>Rs. {overallKitchenShare && overallKitchenShare}</h2>
                            </div>
                            <a href="#wallet" data-bs-toggle="modal" class="btn theme-btn mt-0">Withdraw</a>
                        </div>
                    </div>

                    <div class="heading mt-3">
                        <h3>Withdraw History</h3>
                        <i class="ri-calendar-2-line calendar-icon"></i>
                    </div>
                    <ul class="earning-list mt-3">
                        <li>
                            <a href="#">
                                <div class="earning-box">
                                    <div class="earning-img">
                                        <img class="img-fluid earning-icon" src="../assets/images/delivery-boy/svg/receipt.svg" alt="receipt" />
                                    </div>

                                    <div class="earning-content flex-column">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h6 class="light-text fw-normal">Withdraw Reference Id</h6>
                                            <h6 class="fw-normal light-text">02/12/2024</h6>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h6 class="fw-normal dark-text mt-2">#ACR148856</h6>
                                            <h5 class="fw-semibold theme-color mt-2">Rs. 3200</h5>
                                        </div>
                                    </div>

                                </div>
                            </a>
                            <div class="payment-box">
                                <div class="d-flex align-items-center gap-2">
                                    <img class="img-fluid icon" src="../assets/images/svg/empty-wallet.svg" alt="wallet" />
                                    <h6 class="dark-text fw-normal">G-PAY(050583574)</h6>
                                </div>
                            </div>

                        </li>
                        <li>
                            <a href="#">
                                <div class="earning-box">
                                    <div class="earning-img">
                                        <img class="img-fluid earning-icon" src="../assets/images/delivery-boy/svg/receipt.svg" alt="receipt" />
                                    </div>

                                    <div class="earning-content flex-column">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h6 class="light-text fw-normal">Withdraw Reference Id</h6>
                                            <h6 class="fw-normal light-text">30/11/2023</h6>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h6 class="fw-normal dark-text mt-2">#ACR148856</h6>
                                            <h5 class="fw-semibold theme-color mt-2">Rs. 2000.00</h5>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div class="payment-box">
                                <div class="d-flex align-items-center gap-2">
                                    <img class="img-fluid icon" src="../assets/images/svg/empty-wallet.svg" alt="wallet" />
                                    <h6 class="dark-text fw-normal">G-PAY(050583574)</h6>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>

            <div class="modal order-modal fade" id="wallet" tabIndex="-1">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title">Withdraw Money</h3>
                        </div>
                        <div class="modal-body">
                            <div class="withdraw-box">
                                <form class="auth-form">

                                    <div class="form-group mt-3">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <label class="form-label fw-semibold">Amount</label>
                                            <h5 class="theme-color fw-medium">Available bal : Rs. 1800</h5>
                                        </div>
                                        <input type="number" class="form-control shadow-none" placeholder="Enter amount" />
                                    </div>

                                    <div class="form-group mt-3">
                                        <label for="message" class="form-label fw-semibold">Additional Message</label>
                                        <textarea id="message" rows="3" class=" form-control shadow-none"
                                            placeholder="Enter message"></textarea>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="modal-footer d-flex align-items-center flex-nowrap gap-2 border-0">
                            <a href="/wallet" class="btn gray-btn theme-color w-100 m-0">Cancel</a>
                            <a href="/wallet" class="btn theme-btn w-100 m-0">Send Request</a>
                        </div>
                    </div>
                </div>
            </div>
            <MenuFooter />
        </>
    );
}