import { Link } from "react-router-dom";
import MenuSide from "./MenuSide";
import MenuFooter from "./MenuFooter";
import { useEffect, useState } from "react";
import { useGlobalContext } from "./GlobalContext";
import { Search } from "./service/KitchenMenuService";
import config from "./config";

//create function component as login
export default function KitchenMenuList() {

    const { isLoading, setIsLoading, isAppError, setAppError, appErrorMessage, setAppErrorMessage, appErrorTitle, setAppErrorTitle, appErrorMode, setAppErrorMode, appUser, setAppUser, isLogin, setIsLogin, isLogoutRequest, setIsLogoutRequest, appKitchen } = useGlobalContext();

    const [kitchenMenus, setKitchenMenus] = useState([]);
    const [kitchenName, setKitchenName] = useState('');
    const [kitchen, setKitchen] = useState(null);
    const [keyword, setKeyword] = useState('');

    const fetchKitchenMenus = async () => {
        try {
            setIsLoading(true);
            const response = await Search(appKitchen.id, 0, 0, keyword);
            setKitchenMenus(response.kitchenMenus);
            console.log(response.kitchenMenus);
        } catch (error) {
            setAppError(true);
            setAppErrorTitle('Error');
            setAppErrorMessage('Failed to load data');
            setAppErrorMode('error');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {

        fetchKitchenMenus();
    }, []);

    return (
        <>
            <MenuSide />

            <section className="section-b-space">
                <div className="custom-container">
                    <div className="header d-none">
                        <div className="head-content">
                            <h2>My Kitchen Menu</h2>
                        </div>
                        <Link to="/kitchemenuadd">
                            <i className="ri-add-box-fill"></i>
                        </Link>
                    </div>
                    <div className="btn-block d-none">
                        <Link className="btn active"> Active </Link>
                        <Link className="btn"> Inactive </Link>
                    </div>
                    <div className="row gy-3 mt-5">
                        {/* run kitchenMenus in loop */}
                        {kitchenMenus.map((kitchenMenu, index) => (
                            <div className="col-12">
                                <div className="vertical-product-box order-box">
                                    <div className="vertical-box-img">
                                        <Link to={`/kitchemenuadd/${kitchenMenu.id}`}>
                                            <img className="img-fluid img" src={`${config.apiUrl + "uploads/kitchen/menu/" + kitchenMenu.id + "." + kitchenMenu.image }`} alt={`${kitchenMenu.name}`} />
                                        </Link>
                                    </div>
                                    <div className="vertical-box-details">
                                        <div className="vertical-box-head">
                                            <div className="restaurant">
                                                <h5 className="dark-text">{kitchenMenu.name}</h5>
                                                <h5 className="theme-color">Rs. {kitchenMenu.price}</h5>
                                            </div>
                                            <h6 className="food-items mb-2">{kitchenMenu.ingredient}</h6>
                                            <h6 className="food-items mb-2">{kitchenMenu.preparationMinutes} mins to prepare</h6>
                                            <h6 className="food-items mb-2">{kitchenMenu.foodCusine.name}</h6>
                                        </div>
                                        <div className=" d-none reorder d-flex align-items-center justify-content-between mt-2">
                                            <span className="fw-normal success-color">Available</span>
                                            <div className="switch-btn">
                                                <input type="checkbox" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <MenuFooter />
        </>
    );
}