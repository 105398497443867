import { Link, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import MenuSide from "./MenuSide";
import MenuFooter from "./MenuFooter";
import { mobileValidation } from "./service/Validation";
import { useGlobalContext } from "./GlobalContext";
import { GetByKitchenId } from "./service/InvoiceService";

//create function component as login
export default function OrderSearch() {
    const { isLoading, setIsLoading, isAppError, setAppError, appErrorMessage, setAppErrorMessage, appErrorTitle, setAppErrorTitle, appErrorMode, setAppErrorMode, appUser, setAppUser, isLogin, setIsLogin, isLogoutRequest, setIsLogoutRequest, appKitchen } = useGlobalContext();

    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [orderNumber, setOrderNumber] = useState("");
    const [mobileNum, setMobileNumber] = useState("");
    const [invoices, setInvoices] = useState(null);

    const getInvoices = async () => {

        const responseInvoices = await GetByKitchenId(appKitchen.id);
        if (responseInvoices.status === 200) {
            console.log("fetch data of invoices :", responseInvoices.invoices);
            setInvoices(responseInvoices.invoices);
        }
    }

    useEffect(() => {
        getInvoices();
    }, []);

    const { status } = useParams();
    const orderinfo = async () => {
        if (fromDate === "") {
            setAppError(true);
            setAppErrorMessage("From date is required.");
            return;
        }

        if (toDate === "") {
            setAppError(true);
            setAppErrorMessage("ToDate is required.");
            return;
        }

        if (mobileNum === "") {
            setAppError(true);
            setAppErrorMessage("Mobile number is required.");
        }
        if (!mobileValidation(mobileNum)) {
            setAppError(true);
            setAppErrorMessage("Please enter a 10 digit mobile number.");
        }

        if (orderNumber === "") {
            setAppError(true);
            setAppErrorMessage("Order number is required.");
        }
        // Implement actual coupon creation logic here
        //alert("Coupon creation logic");
        window.location.href = "/ordersearch/new";
    }

    return (
        <>
            <MenuSide />

            <section className="section-b-space">
                <div className="custom-container d-none">
                    <form className="auth-form" target="_blank">
                        <h4>Search Order - <b>{status.toUpperCase()}</b></h4>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">From Date</label>
                                    <input type="date" className="form-control shadow-none" placeholder="" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">To Date</label>
                                    <input type="date" className="form-control shadow-none" placeholder="" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Order Number</label>
                                    <input type="number" className="form-control shadow-none" placeholder="" value={orderNumber} onChange={(e) => setOrderNumber(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Mobile Number</label>
                                    <input type="number" className="form-control shadow-none" placeholder="" value={mobileNum} onChange={(e) => setMobileNumber(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <button type="button" className="w-100 btn btn-success" onClick={orderinfo}> Load Orders </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="custom-container" style={{ marginTop: "40px" }}>
                    <ul className="earning-list">
                        {/* loop invoices */}
                        {invoices && invoices.map((invoice, index) => (
                            <li key={index}>                                
                                <Link to={`/orderdetail/${invoice.id}`}>
                                    <div className="earning-box">
                                        <div className="earning-img">
                                            <img className="img-fluid earning-icon"
                                                src="../assets/images/delivery-boy/svg/receipt.svg" alt="receipt" />
                                        </div>

                                        <div className="earning-content flex-column">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h6 className="light-text fw-medium">Order No</h6>
                                                <h6 className="fw-normal light-text">{invoice.order.createdDateTime.date.slice(0, 19)}</h6>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-2">
                                                <h6 className="fw-mediun dark-text">#{invoice.invoiceNo}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                <div className="payment-box order-person-details">
                                    <div>
                                        <div className="d-flex align-items-center gap-2">
                                            <img className="img-fluid icon" src="../assets/images/svg/user.svg" alt="wallet" />
                                            <h6 className="dark-text fw-medium">{invoice.order.buyer.user.name}</h6>
                                        </div>

                                        <div className="d-flex align-items-center gap-2 mt-2">
                                            <img className="img-fluid icon"
                                                src="../assets/images/delivery-boy/svg/wallet-money.svg" alt="wallet-money" />
                                            <h6 className="fw-medium dark-text">Rs. {invoice.orderTotal}</h6>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-flex align-items-center gap-2">
                                            <img className="img-fluid icon" src="../assets/images/svg/call-line.svg"
                                                alt="wallet" />
                                            <h6 className="dark-text fw-medium">{invoice.order.buyer.user.mobile}</h6>
                                        </div>

                                        <div className="d-flex align-items-center gap-2 mt-2">
                                            <img className="img-fluid icon" src="../assets/images/svg/empty-wallet.svg"
                                                alt="wallet-money" />
                                            <h6 className="fw-medium dark-text">{invoice.order.payOnline === true ? <>Paid Online</> : invoice.order.payOnline === false ? <>COD</> : <></>}</h6>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}                        
                    </ul>
                </div>
            </section>

            <MenuFooter />
        </>
    );
}