import { Link } from "react-router-dom";
import MenuSide from "./MenuSide";
import MenuFooter from "./MenuFooter";

//create function component as login
export default function CouponList() {

    return (
        <>
            <MenuSide />

            <section className="section-t-space">
                <div className="custom-container">
                    <div className="header d-none">
                        <div className="head-content">
                            <h2>List My Coupons</h2>
                        </div>
                        <Link to="/couponadd">
                            <i className="ri-add-box-fill"></i>
                        </Link>
                    </div>
                    <ul class="coupon-list">
                        <li>
                            <div class="offer-box">
                                <div class="offer-icon">
                                    <img class="img-fluid offer" src="../assets/images/icons/Offer-Discount.png" alt="offer" />
                                </div>
                                <div class="offer-content">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <a href="#coupon" data-bs-toggle="modal">
                                            <h3 class="fw-semibold dark-text">20% OFF</h3>
                                        </a>
                                        <div class="switch-btn">
                                            <input type="checkbox" />
                                        </div>
                                    </div>
                                    <div>
                                        <h6 class="light-text">Code AHNBGT234</h6>
                                        <h6 class="fw-normal dark-text">Total User : 08</h6>
                                        <h6 class="fw-normal dark-text">Max Discount : Rs. 100</h6>
                                        <h6 class="fw-normal dark-text">Valid Until : 1-02-2024 to 20-02-2024</h6>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="offer-box">
                                <div class="offer-icon">
                                    <img class="img-fluid offer" src="../assets/images/icons/Offer-Discount.png" alt="offer" />
                                </div>
                                <div class="offer-content">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <a href="#coupon" data-bs-toggle="modal">
                                            <h3 class="fw-semibold dark-text">10% OFF</h3>
                                        </a>
                                        <div class="switch-btn">
                                            <input type="checkbox" />
                                        </div>
                                    </div>
                                    <div>
                                        <h6 class="light-text">Code VIDAMUR010</h6>
                                        <h6 class="fw-normal dark-text">Total User : 100</h6>
                                        <h6 class="fw-normal dark-text">Max Discount : Rs. 100</h6>
                                        <h6 class="fw-normal dark-text">Valid Until : 1-02-2024 to 20-02-2024</h6>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="offer-box">
                                <div class="offer-icon">
                                    <img class="img-fluid offer" src="../assets/images/icons/Offer-Discount.png" alt="offer" />
                                </div>
                                <div class="offer-content">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <a href="#coupon" data-bs-toggle="modal">
                                            <h3 class="fw-semibold dark-text">25% OFF</h3>
                                        </a>
                                        <div class="switch-btn">
                                            <input type="checkbox" checked />
                                        </div>
                                    </div>
                                    <div>
                                        <h6 class="light-text">Code ICC0025</h6>
                                        <h6 class="fw-normal dark-text">Total User : 25</h6>
                                        <h6 class="fw-normal dark-text">Max Discount : Rs. 250</h6>
                                        <h6 class="fw-normal dark-text">Valid Until : 10-02-2024 to 26-02-2024</h6>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            <MenuFooter />
        </>
    );
}