import { Link, useParams } from "react-router-dom";
import MenuSide from "./MenuSide";
import MenuFooter from "./MenuFooter";
import { useNavigate } from 'react-router-dom';
import { GetById } from "./service/InvoiceService";
import { useEffect, useState } from "react";
import { useGlobalContext } from "./GlobalContext";

//create function component as login
export default function OrderDetail() {

    const { isLoading, setIsLoading, isAppError, setAppError, appErrorMessage, setAppErrorMessage, appErrorTitle, setAppErrorTitle, appErrorMode, setAppErrorMode, appUser, setAppUser, isLogin, setIsLogin, isLogoutRequest, setIsLogoutRequest, appKitchen } = useGlobalContext();

    const navigate = useNavigate();

    const { id } = useParams();

    const [invoice, setInvoice] = useState(null);
    const [orderMenus, setOrderMenus] = useState([]);
    const [kitchenShare, setKitchenShare] = useState(null);
    const [portalShare, setPortalShare] = useState(null);

    const handleGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (id != null && id != undefined && id != "") {
            fetchOrder();
        }
    }, []);

    const fetchOrder = async () => {
        setIsLoading(true);
        try {
            const response = await GetById(id);
            if (response.status === 200) {
                const data = response.invoice;
                console.log("fetch data of invoice :", response.orderMenus);
                setInvoice(response.invoice);
                setKitchenShare(response.kitchenShare);
                setPortalShare(response.portalShare);
                setOrderMenus(response.orderMenus);
            }

        } catch (error) {
            setAppError(true);
            setAppErrorMessage('Failed to load kitchen data');
            setAppErrorTitle('Error');
            setAppErrorMode('error');
        } finally {
            setIsLoading(false);
        }
    };

    const findActalPrice = (price, discount, tax) => {
        let actualPrice = price - (price * discount / 100) + (price * tax / 100);
        return actualPrice.toFixed(2);
    };

    return (
        <>
            <MenuSide />

            <section className="section-b-space pt-5">
                <div className="custom-container mt-3">
                    <Link onClick={handleGoBack} className="btn btn-sm btn-success" style={{ marginTop: "40px", marginBottom: "20px" }}> Go Back </Link>
                    <ul className="earning-list mt-0">
                        <li>
                            <a href="order-details.html">
                                <div className="earning-box">
                                    <div className="earning-img">
                                        <img className="img-fluid earning-icon" src="../assets/images/delivery-boy/svg/receipt.svg" alt="receipt" />
                                    </div>

                                    <div className="earning-content">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h6 className="light-text fw-medium">Order No : {invoice && invoice.invoiceNo}</h6>
                                            <h6 className="fw-normal light-text">Order At : {invoice && invoice.order.createdDateTime.date.slice(0, 19)}</h6>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div className="payment-box order-payment-details">
                                <div className="box-content">
                                    <h6 className="light-text ps-0 border-0">Payment By</h6>
                                    <h5 className="success-color mt-1">{invoice && invoice.order.payOnline === true ? <>Paid Online</> : invoice && invoice.order.payOnline === false ? <>COD</> : <></>}</h5>
                                </div>
                                <div className="box-content border-0 ps-4">
                                    <h6 className="light-text ps-0 border-0">Total Payment</h6>
                                    <h5 className="dark-text mt-1">Rs. {invoice && invoice.orderTotal}</h5>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div className="order-item-table">
                        <table className="table table-borderless mb-0">
                            <thead>
                                <tr>
                                    <th>Order Items</th>
                                    <th className="text-center">Qnt.</th>
                                    <th className="text-end">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* run orderMenus in loop */}
                                {orderMenus.map((orderMenu) => (
                                    <tr>
                                        <td>{orderMenu.kitchenMenu.name}</td>
                                        <td className="text-center">{orderMenu.unit}</td>
                                        <td className="text-end">Rs. {findActalPrice(orderMenu.price, orderMenu.discount, orderMenu.tax) * orderMenu.unit}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td>Sub Total</td>
                                    <td className="text-center"></td>
                                    <td className="text-end">Rs. {invoice && invoice.orderTotal}</td>
                                </tr>
                            </tbody>

                            <tbody>
                                <tr>
                                    <td>Delivery Charges</td>
                                    <td></td>
                                    <td className="text-end">Rs. 40</td>
                                </tr>
                            </tbody>
                            <tbody className="border-0">
                                <tr>
                                    <td className="fw-semibold dark-text pb-0">Payable</td>
                                    <td></td>
                                    <td className="text-end fw-semibold theme-color sub-total pb-0">Rs. {invoice && invoice.orderTotal + 40}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="shipping-details">
                        <h5 className="title-content">Buyer Details</h5>

                        <div className="shipping-content">
                            <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="d-flex align-items-center gap-2 w-100">
                                    <img className="img-fluid icon" src="../assets/images/svg/user.svg" alt="wallet" />
                                    <h6 className="dark-text fw-medium">{invoice && invoice.order.buyer.user.name.toUpperCase()}</h6>
                                </div>

                                <div className="d-flex align-items-center flex-nowrap gap-2 w-100">
                                    <img className="img-fluid icon" src="../assets/images/svg/call-line.svg" alt="wallet" />
                                    <h6 className="fw-medium dark-text"><a target="_blank" href="`tel:+91${invoice && invoice.order.buyer.user.mobile}`">{invoice && invoice.order.buyer.user.mobile}</a></h6>
                                </div>
                            </div>
                            <div className="d-flex align-items-center gap-2 mt-2">
                                <img className="img-fluid icon" src="../assets/images/svg/sms.svg" alt="wallet" />
                                <h6 className="dark-text fw-medium">{invoice && invoice.order.buyer.user.email}</h6>
                            </div>

                            <div className="d-flex align-items-center gap-2 mt-2">
                                <img className="img-fluid icon" src="../assets/images/svg/location.svg" alt="wallet" />
                                <h6 className="fw-medium dark-text">{invoice && invoice.order.buyerLocation.addressLine1} <br></br> {invoice && invoice.order.buyerLocation.addressLine2} <br></br> {invoice && invoice.order.buyerLocation.city.name} - {invoice && invoice.order.buyerLocation.pincode}</h6>
                            </div>

                            <div className="d-flex align-items-center gap-2 mt-2">
                                <img className="img-fluid icon" src="../assets/images/svg/food.svg" alt="wallet" />
                                <h6 className="fw-medium dark-text">{invoice && invoice.order.kitchenInstruction}</h6>
                            </div>
                        </div>
                    </div>

                    <div className="notify-part">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                            <label className="form-check-label fw-medium dark-text" htmlFor="flexCheckDefault">Notify the delivery boy of the new order</label>
                        </div>
                        <button type="button" className="w-100 mt-6 btn btn-info"> Put To In Progress </button>
                    </div>
                </div>

            </section>

            <MenuFooter />

            {/* modal popup  */}
            <div className="modal order-modal fade" id="custom1" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Veg Frankie</h3>
                        </div>
                        <div className="modal-body">
                            <div className="order-item-table">
                                <table className="table table-borderless mb-0">
                                    <thead>
                                        <tr>
                                            <th colSpan={3}>Dips</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Chilli</td>
                                            <td className="text-center">1</td>
                                            <td className="text-end">Rs. 40.00</td>
                                        </tr>
                                        <tr>
                                            <td>Mayonesse</td>
                                            <td className="text-center">2</td>
                                            <td className="text-end">RS. 20.00</td>
                                        </tr>
                                    </tbody>
                                    <tbody className="border-0">
                                        <tr>
                                            <td className="fw-semibold dark-text pb-0">Menu Total</td>
                                            <td></td>
                                            <td className="text-end fw-semibold theme-color sub-total pb-0">Rs. 55.00</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}